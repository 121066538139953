<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.forfeiture_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type"
                    >
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        disabled
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Stall Category" vid="stall_category" rules="" v-slot="{ errors }">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.space_category') }}
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.forfeiture_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" v-if="finalList.length > 0" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.forfeiture_report') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="12" class="text-left">
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="12" class="text-left">
              <span>{{ $t('fair_report.space_category') }}</span>:
              <strong>{{ parseInt(search.stall_category) ? getStallCategoryName(search.stall_category) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <template>
                    <table style="width:100%;" class="mb-4 table">
                      <thead>
                        <tr>
                          <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                          <th class="text-center">{{ $t('fair_report.name_address_mobile') }}</th>
                          <th class="text-center">{{ $t('fair_report.stall_type') }}</th>
                          <th class="text-center">{{ $t('fair_report.processing_fee') }}</th>
                          <th class="text-center">{{ $t('fair_report.forfeiture_security_money') }}</th>
                          <th class="text-center">{{ $t('fair_report.allocated_stall') }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="finalList.length > 0">
                        <template v-for="(app, index) in finalList">
                          <tr v-for="(pavilion, index1) in app.forfeitures" :key="index1">
                            <slot v-if="index1 === 0">
                              <td :rowspan="app.forfeitures.length">{{ $n(index + 1) }}</td>
                              <td :rowspan="app.forfeitures.length">
                                <strong>{{ currentLocale == 'bn' ?  app.comp_name_bn : app.comp_name_en }}</strong> <br>
                                <strong>{{ app.mobile_no | mobileNumber }}</strong>
                                <br>
                                {{ AddressHelper.getCommonAddress(app.address) }}
                              </td>
                            </slot>
                            <td>{{ getStallCategoryName(pavilion.stall_cat_id) }}</td>
                            <td class="text-right">{{ $n(pavilion.processing_fee, { minimumFractionDigits: 2 }) }}</td>
                            <td class="text-right">{{ $n(pavilion.security_money, { minimumFractionDigits: 2 }) }}</td>
                            <td>
                              <span v-if="app?.forfeitures.length > 0">
                                {{ getStallName(pavilion.stall_cat_id, app?.forfeitures) }}
                              </span>
                            </td>
                          </tr>
                        </template>
                        <tr>
                            <td colspan="4" class="text-right">{{ $t('globalTrans.total') }}</td>
                            <td class="text-right">{{ $n(getTotalForfeitureMoney(), { minimumFractionDigits: 2}) }}</td>
                            <td></td>
                          </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfSecurityForfeitureReport, getMasterStalls } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'

import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    // this.loadData()
    this.getStall()
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      priceType: 0,
      processingFee: 0,
      stallSize: '',
      circular: '',
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      stallList: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        stall_category: 0,
        stall_id: 0
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        stall_category: 0,
        stall_id: 0
      },
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalList = []
        // this.stallList = []
        this.search.stall_id = 0
        // this.stallList = this.getStall(newVal)
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    getStallCategoryName (id) {
        const dataStallCategory = this.stallCategoryList.find(item => item.value === parseInt(id))
        if (typeof dataStallCategory !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataStallCategory.text_en : dataStallCategory.text_bn
        } else {
          return ''
        }
    },
    getStallName (stallId, bookedPavilions) {
        const bookedStall = bookedPavilions.find(item => item.stall_cat_id === parseInt(stallId))

        if (bookedStall !== undefined) {
          const stallCategory = this.stallCategoryList.find(item => item.value === parseInt(bookedStall.stall_cat_id))

          if (parseInt(stallCategory.price_type) === 1) {
            return bookedStall.booked_stall_no
          } else {
            const stallData = this.stallList.find(item => item.value === parseInt(bookedStall.stall_id))
            return stallData !== undefined ? stallData.text : ''
          }
        } else {
          return ''
        }
    },
    getTotalForfeitureMoney () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallForfeitures = data.forfeitures || []
            const totalForThisData = stallForfeitures.reduce((total, stallReturn) => {
                const fee = Number(stallReturn.security_money) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfSecurityForfeitureReport, this.search)
      if (result.success) {
        this.finalList = result.data
        this.isResult = false
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getCategoryName (id) {
      const companyType = this.stallCategoryList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    async getStall (stallCat) {
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, getMasterStalls)
      if (result.success) {
        this.stallList = result.data
      } else {
        this.stallList = []
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign(
        {
        request_type: 'pdf',
        local: this.$i18n.locale,
        org_id: 2,
        year: this.search.year,
        stall_category: this.search.stall_category,
        fair_id: this.search.fair_id,
        circular_type: this.search.circular_type,
        total_forfeiture_money: this.getTotalForfeitureMoney()
        })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfSecurityForfeitureReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
